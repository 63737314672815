<template>
	<div class="my-2 p-2 rounded pt-2" style="background-color:#f3f3f3;">
		<slot name="header">
			<div v-if="index > 0" class="d-flex flex-row align-items-center justify-content-between">
				<div class="font-weight-bold">
					- {{condition|label(conditions, 'symbol')}}
				</div>
				<el-button type="text" icon="el-icon-close" class="text-dark font-weight-bold p-2" @click="$emit('remove')"></el-button>
			</div>
		</slot>
		<slot>
			<table class="pixel-card-table">
				<tbody>
					<tr v-if="keys.find(v => v === 'pixelId')">
						<td>
							<div style="line-height:2em;">数据源:</div>
						</td>
						<td>
							<el-select v-model="form.pixelId" size="mini" class="w-100">
								<el-option v-for="pixel in pixels" :label="pixel.name" :value="pixel.id">
									<span style="float:left;font-size:12px;font-weight:700;">{{ pixel.name }}</span>
									<span style="float:right;color:#8492a6;font-size:12px;">{{ pixel.id }}</span>
								</el-option>
							</el-select>
						</td>
					</tr>
					<tr v-if="keys.find(v => v === 'event')">
						<td>
							<div style="line-height:2em;">事件:</div>
						</td>
						<td>
							<el-select v-model="form.event" size="mini" class="w-100">
								<el-option v-for="event in events" :label="event.label" :value="event.key">
									<span style="float:left;font-size:12px;font-weight:700;">{{ event.label }}</span>
								</el-option>
							</el-select>
						</td>
					</tr>
					<tr v-if="keys.find(v => v === 'days')">
						<td>
							<div style="line-height:2em;">时间窗口:</div>
						</td>
						<td>
							<el-input-number v-model="form.days" :controls="false" size="mini" class="mr-2" :min="3" :max="180"></el-input-number> days
						</td>
					</tr>
				</tbody>
			</table>
		</slot>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		props: {
			keys: {
				type: Array,
				default: (() => ['pixelId', 'event', 'days'])
			},
			pixels: {
				type: Array,
				default: (() => [])
			},
			condition: {
				type: String,
				default: 'OR'
			},
			form: {
				type: Object,
				default: (() => {})
			},
			index: {
				type: Number,
				default: 0
			}
		},
		filters: {
			label(key, conditions, symbol) {
				const val = conditions.find(v => v.key === key)
				return val && symbol && val[symbol]
			}
		},
		computed: {
			...mapGetters({
				events: 'fb-contents-audience/events',
				conditions: 'fb-contents-audience/conditions',
			})
		}
	}
</script>

<style lang="scss" scoped>
	:deep(table.pixel-card-table) {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0px 5px;
		tr {
			td:nth-child(odd) {
				width: 20%;
			}
			td:nth-child(even) {
				width: 80%;
			}
		}
	}
</style>