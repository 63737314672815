<template>
	<el-dialog :visible.sync="visible" :before-close="handleClose" width="600px" :append-to-body="true" :show-close="false" :destroy-on-close="true">
		<div slot="title" class="d-flex flex-row align-items-center">
			<div class="mr-2">Custom Audience Source</div>
			<el-select v-if="form.source" v-model="form.source" size="mini" @change="sourceChanged">
				<el-option v-for="item in sources" :label="item.label" :value="item.key"></el-option>
			</el-select>
		</div>
		<div class="custom-audience-dialog-configs">
			<!-- PIXEL -->
			<template v-if="form.source === 'PIXEL' && form.pixel !== null">
				<div>包含复合以下
					<el-dropdown size="mini" @command="handleCommand">
						<span class="text-primary">
							{{form.pixel.include.operator|label(conditions)}}
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="item in conditions" :command="item.key">{{item.label}}
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					条件的用户
				</div>
				<!-- Include -->
				<el-row>
					<el-col :span="18">
						<div>
							<pixel-card v-for="(item, index) in form.pixel.include.dataSourceList" :pixels="pixels" :form="item"
								:index="index" @remove="form.pixel.include.dataSourceList.splice(index, 1)" :condition="form.pixel.include.operator"
								:key="'includes' + index">
							</pixel-card>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="d-flex flex-column align-items-center">
							<div class="my-3">
								<el-button style="border:1px dashed;width:100px;" size="mini"
									@click="doAction('add.pixel.includes')">+ 添加条件</el-button>
							</div>
							<div>
								<el-button style="border:1px dashed;width:100px;" size="mini"
									@click="doAction('add.pixel.excludes')">- 排除用户</el-button>
							</div>
						</div>
					</el-col>
				</el-row>
				<!-- Exclude -->
				<div v-if="form.pixel.exclude && form.pixel.exclude.dataSourceList && form.pixel.exclude.dataSourceList.length > 0" class="border p-2">
					<div class="d-flex flex-row align-items-center justify-content-between">
						<div>排除复合以下任一条件的用户</div>
						<el-button type="text" class="text-dark font-weight-bold" @click="doAction('reset.pixel.excludes')">X
						</el-button>
					</div>
					<el-row>
						<el-col :span="18">
							<div>
								<pixel-card v-for="(item, index) in form.pixel.exclude.dataSourceList" :pixels="pixels" :form="item"
									:index="index" @remove="form.pixel.exclude.dataSourceList.splice(index, 1)" :key="'excludes' + index">
								</pixel-card>
							</div>
						</el-col>
						<el-col :span="6">
							<div class="d-flex flex-column align-items-center">
								<div class="my-3">
									<el-button style="border:1px dashed;width:100px;" size="mini"
										@click="doAction('add.pixel.excludes.one')">+ 添加条件</el-button>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</template>
			<!-- CATALOG -->
			<template v-if="form.source === 'CATALOG' && form.catalog !== null">
				<table class="custom-audience-dialog-catelog-config">
					<tbody>
						<tr>
							<td>
								<div>数据源:</div>
							</td>
							<td>
								<el-select v-model="form.catalog.catalogId" size="mini" @change="catalogIdChanged" class="w-100">
									<el-option v-for="item in catalogs" :label="item.name" :value="item.id" :key="item.id"></el-option>
								</el-select>
							</td>
						</tr>
						<tr>
							<td>
								<div>商品组:</div>
							</td>
							<td>
								<el-select v-model="form.catalog.productSetId" size="mini" class="w-100">
									<el-option v-for="item in productGroups" :label="item.name" :value="item.id" :key="item.id">
										<span style="float:left;font-size:12px;font-weight:700;">{{ item.name }}</span>
										<span style="float:right;color:#8492a6;font-size:12px;">{{ item.id }}</span>
									</el-option>
								</el-select>
							</td>
						</tr>
					</tbody>
				</table>
				<div>包含复合以下
					<el-dropdown size="mini" @command="handleCatalogCommand">
						<span class="text-primary">
							{{form.catalog.operator|label(conditions)}}
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item v-for="item in conditions" :command="item.key">{{item.label}}
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					条件的用户
				</div>
				<el-row>
					<el-col :span="18">
						<div>
							<pixel-card v-for="(item, index) in form.catalog.events" :keys="['event', 'days']" :form="item"
								:index="index" @remove="form.catalog.events.splice(index, 1)" :condition="form.catalog.operator"
								:key="'catalog-includes' + index">
							</pixel-card>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="d-flex flex-column align-items-center">
							<div class="my-3">
								<el-button style="border:1px dashed;width:100px;" size="mini"
									@click="doAction('add.catalog.includes')">+ 添加条件</el-button>
							</div>
						</div>
					</el-col>
				</el-row>
			</template>
		</div>
		<div slot="footer" class="dialog-footer border-top pt-3">
			<table class="custom-audience-dialog-summary">
				<tbody>
					<tr>
						<td>
							<small>Audience 命名:</small>
						</td>
						<td>
							<el-input v-model="form.name" size="mini"></el-input>
						</td>
					</tr>
					<tr>
						<td>
							<small>描述:</small>
						</td>
						<td>
							<el-input v-model="form.description" size="mini"></el-input>
						</td>
					</tr>
				</tbody>
			</table>
			<el-button @click="$emit('update:visible', false)" size="mini">取 消</el-button>
			<el-button type="primary" size="mini" @click="saveCustomAudience">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	const DEFAULT_PIXEL_DATA = {
		pixelId: null,
		event: null,
		days: 3
	}
	const DEFAULT_CATALOG = {
		catalogId: "",
		productSetId: "",
		events: null,
		operator: "AND",
	}
	const DEFAULT_PIXEL = {
		include: {
			dataSourceList: [{...DEFAULT_PIXEL_DATA}],
			operator: "AND"
		},
		exclude: {
			dataSourceList: [],
			operator: "OR"
		}
	}
	const DEFAULT_FORM = {
		source: '',
		name: '',
		description: '',
		website: '',
		catalog: null,
		pixel: null
	}
	import Common from '@/common/mixins/common.js'
	import {
		mapGetters
	} from 'vuex'
	import PixelCard from './pixel-card.vue'
	import {
		fetchJSONByApis
	} from '@/apis/index.js'
	import Lodash from 'lodash'
	export default {
		components: {
			PixelCard
		},
		mixins: [Common],
		inject: ['adminLayout'],
		data() {
			return {
				form: {
					...DEFAULT_FORM
				},
				pixels: [],
				catalogs: [],
				productGroups: []
			}
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			website: {
				type: String,
				default: ''
			},
			customAudience: {
				type: Object,
				default: null
			}
		},
		watch: {
			visible(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						// new object default configurations
						this.form = {
							...DEFAULT_FORM, 
							website: this.website, 
							source: this.sources[0].key, 
							pixel: Lodash.cloneDeep(DEFAULT_PIXEL)
						}
						this.__init()
					})
				} else {
					this.$nextTick(() => {
						this.pixels = []
						this.catalogs = []
					})
				}
			}
		},
		computed: {
			...mapGetters({
				events: 'fb-contents-audience/events',
				sources: 'fb-contents-audience/sources',
				conditions: 'fb-contents-audience/conditions'
			})
		},
		filters: {
			label(key, values) {
				const val = values.find(v => v.key === key)
				return val && val.label
			},
		},
		methods: {
			__init() {
				this.adminLayout.showLoading(true)
				const apis = [
					this.$store.dispatch('fb-contents-audience/fetchAllPixels', this.website),
					this.$store.dispatch('fb-contents-audience/fetchAllCatalogs', this.website)
				]
				fetchJSONByApis(...apis)
					.then(result => {
						this.pixels = Object.keys(result[0]).map(key => {
							return {
								id: key,
								name: result[0][key]
							}
						})
						this.catalogs = result[1]
						this.adminLayout.hideLoading()
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
			},
			doAction(command) {
				switch (command) {
					case 'add.pixel.includes':
						this.form.pixel.include.dataSourceList.push({
							...DEFAULT_PIXEL_DATA
						})
						break
					case 'add.pixel.excludes':
						if (this.form.pixel.exclude.dataSourceList.length > 0) {break}
						// throughdown
					case 'add.pixel.excludes.one':
						this.form.pixel.exclude.dataSourceList.push({
							...DEFAULT_PIXEL_DATA
						})
						break
					case 'reset.pixel.excludes':
						this.form.pixel.exclude.dataSourceList = []
						break
					case 'add.catalog.includes':
						this.form.catalog.events.push({
							...DEFAULT_PIXEL_DATA
						})
						break
					case 'remove':
						this.form.excludes = []
				}
			},
			handleCommand(command) {
				this.form.pixel.include.operator = command
			},
			handleCatalogCommand(command) {
				this.form.catalog.operator = command
			},
			async validateForm() {
				return new Promise((resolve, _) => {
					if (this.form.name.trim() === '') {
						resolve(false)
						return
					}
					if (this.form.source === 'PIXEL') {
						const includeEmptyData = this.form.pixel.include.dataSourceList.find(v => !v.pixelId || !v.event)
						if (includeEmptyData !== undefined) {
							resolve(false)
							return
						}
						const excludeEmptyData = this.form.pixel.exclude.dataSourceList.find(v => !v.pixelId || !v.event)
						if (excludeEmptyData !== undefined) {
							resolve(false)
							return
						}
						resolve(true)
					} else if (this.form.source === 'CATALOG') {
						if (!this.form.catalog.catalogId) {
							resolve(false)
							return
						}
						if (!this.form.catalog.productSetId) {
							resolve(false)
							return
						}
						const emptyEvent = this.form.catalog.events.find(v => !v.event)
						if (emptyEvent !== undefined) {
							resolve(false)
							return
						}
						resolve(true)
					}
				})
			},
			async convertFormForSubmit() {
				return new Promise((resolve, _) => {
					var pixel = null
					var catalog = null
					if (this.form.source === 'PIXEL') {
						const excludeDataSourceList = this.form.pixel.exclude.dataSourceList.map(({pixelId, event, days}) => {
							return {
								pixelId,
								event,
								retentionSeconds: days * 3600 * 24
							}
						})
						var exclude = null
						if (excludeDataSourceList.length > 0) {
							exclude = {
								...this.form.pixel.exclude,
								dataSourceList: excludeDataSourceList
							}
						}
						pixel = {
							...this.form.pixel,
							include: {
								...this.form.pixel.include,
								dataSourceList: this.form.pixel.include.dataSourceList.map(({pixelId, event, days}) => {
									return {
										pixelId,
										event,
										retentionSeconds: days * 3600 * 24
									}
								})
							},
							exclude
						}
					} else if (this.form.source === 'CATALOG') {
						const events = this.form.catalog.events.map(({event, days}) => {
							return {
								event,
								retentionDays: days
							}
						})
						catalog = {
							...this.form.catalog,
							events
						}
					}
					const form = {
						...this.form,
						name: this.form.name.trim(),
						description: this.form.description.trim(),
						pixel,
						catalog
					}
					resolve(form)
				})
			},
			saveCustomAudience() {
				this.validateForm()
				.then((flag) => {
					if (flag) {
						this.adminLayout.showLoading(true)
						this.convertFormForSubmit()
						.then(form => {
							this.$store.dispatch('fb-contents-audience/newItemOfCustomAudience', form)
							.then(res => {
								this.adminLayout.hideLoading()
								this.$showSuccess('创建成功')
								this.$emit('update:visible', false)
								this.$emit('reloadData')
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						})
					} else {
						this.$showErrMsg('请完整填写配置后再保存')
					}
				})
			},
			sourceChanged() {
				if (this.form.source === 'PIXEL') {
					this.form.pixel = Lodash.cloneDeep(DEFAULT_PIXEL)
					this.form.catalog = null
				} else if (this.form.source === 'CATALOG') {
					this.form.pixel = null
					this.form.catalog = {
						...Lodash.cloneDeep(DEFAULT_CATALOG),
						events: [{...DEFAULT_PIXEL_DATA}]
					}
				}
			},
			catalogIdChanged() {
				const selCatalogId= this.form.catalog && this.form.catalog.catalogId || ''
				const catalog = this.catalogs.find(v => v.id === selCatalogId)
				if (catalog !== undefined) {
					this.productGroups = catalog.productGroups || []
				} else {
					this.productGroups = []
				}
				this.form.catalog.productSetId = ''
			}
		}
	}
</script>

<style lang="scss" scoped>
	:deep(div.custom-audience-dialog-configs) {
		padding: 5px;
		height: 300px;
		overflow-y: auto;
	}

	:deep(table.custom-audience-dialog-summary) {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 5px;

		td:nth-child(odd) {
			width: 20%;
			text-align: left;
		}

		td:nth-child(even) {
			width: 80%;
		}
	}

	:deep(table.custom-audience-dialog-catelog-config) {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 5px;

		td:nth-child(odd) {
			width: 10%;
		}

		td:nth-child(even) {
			width: 90%;
		}
	}
</style>
