<template>
	<table class="form-interests-table">
		<tbody>
			<tr v-for="(item, index) in interests">
				<td>Insterest {{index|labelIndex}}:</td>
				<td>
					<el-input v-model="item.text" placeholder="请输入" style="width:300px;" size="mini"></el-input>
				</td>
				<td>
					<el-button v-if="index===0" style="border:1px dashed;width:60px;" size="mini" @click="interests.push({text:''})">+添加</el-button>
					<el-button v-else type="text" class="text-danger" size="mini" @click="interests.splice(index, 1)" style="width:60px;">删除</el-button>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
	export default {
		props: {
			interests: {
				type: Array,
				default: (() => {
					return [{text: ''}]
				})
			}
		},
		filters: {
			labelIndex(index) {
				if (index < 9) {
					return `0${index+1}`
				}
				return index + 1
			}
		}
	}
</script>

<style lang="scss" scoped>
	table.form-interests-table {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 5px;
		margin-top: -5px;
		td:nth-child(n+1) {
			width: 20%;
		}
		td:nth-child(n+2) {
			width: 65%;
		}
		td:nth-child(n+3) {
			width: 15%;
		}
	}
</style>