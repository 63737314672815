<template>
	<div class="p-2 h-100 d-flex flex-column">
		<div class="d-flex flex-row align-items-center justify-content-between">
			<div>{{namespace|title}}</div>
			<div>
				<el-button type="text" size="mini" @click="visible=true">创建</el-button>
				<el-button type="text" class="text-info" size="mini" @click="$emit('closeDraw')">关闭</el-button>
			</div>
		</div>
		<el-table
			size="mini"
			height="auto"
			border
			width="100%"
		    :data="tableData">
		      <el-table-column
		        prop="createdAt"
		        label="Date"
				align="center"
				v-if="namespace === 'CustomAudience'"
				>
				<template slot-scope="scope">
					{{scope.row.createdAt|formatDate}}
				</template>
		      </el-table-column>
		      <el-table-column
		        prop="name"
		        label="Name"
				align="center"
		        >
		      </el-table-column>
			  <el-table-column
			    prop="description"
			    label="Description"
			  	align="center"
				v-if="namespace === 'CustomAudience'"
			    >
			  </el-table-column>
			  <el-table-column
			    prop="source"
			    label="Source"
			  	align="center"
				v-if="namespace === 'CustomAudience'"
			    >
			  </el-table-column>
			  <el-table-column
			    prop="sourceType"
			    label="Source Type"
			  	align="center"
			  	v-if="namespace === 'LookalikeAudience'"
			    >
			  </el-table-column>
			  <el-table-column
			    prop="countryCode"
			    label="Country Code"
			  	align="center"
			  	v-if="namespace === 'LookalikeAudience'"
			    >
			  </el-table-column>
		      <!-- <el-table-column
			  align="center"
		        label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="mini" @click="editRow(scope.row)">编辑</el-button>
				</template>
		      </el-table-column> -->
		    </el-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<component :is="componentDialog" :visible.sync="visible" :website="website" @reloadData="reloadData"></component>
	</div>
</template>

<script>
	import Page from '@/common/mixins/page.js'
	import Common from '@/common/mixins/common.js'
	import CustomAudienceDialog from './custom-audience-dialog.vue'
	import LookalikeAudienceDialog from './lookalike-audience-dialog.vue'
	export default {
		components: {
			CustomAudienceDialog,
			LookalikeAudienceDialog
		},
		mixins: [Page, Common],
		inject: ['formCustomAudiences'],
		props: {
			q: {
				type: Object,
				default: (() => {})
			},
			namespace: {
				type: String,
				default: ''
			},
			website: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				tableData: [],
				visible: false
			}
		},
		computed: {
			componentDialog() {
				return this.namespace + 'Dialog'
			}
		},
		filters: {
			title(namespace) {
				const v = namespace.split('Audience')
				return [...v, 'Audience'].join(' ')
			}
		},
		methods: {
			async getListUrl() {
				const trigger = this.trigger
				const payload = {
					params: {
						...this.page,
						...this.q,
						website: this.website
					},
					trigger
				}
				return this.$store.dispatch('fb-contents-audience/getListOf' + this.namespace, payload)
			},
			handleList(content) {
				this.tableData = content
			},
			editRow(row) {
				console.log(row)
			},
			reloadData() {
				this.formCustomAudiences.__initCustomAudiences(true)
				this.getList()
			}
		}
	}
</script>

<style>
</style>