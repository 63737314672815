<template>
	<el-card>
		<advanced-search-bar>
			<template>
				<form-item-sites :abbr="true" :multiple="false" :local="true" v-model="queryForm.website" :parent="false" @onload="q.website=queryForm.website;getList()" :clearable="false"></form-item-sites>
				<form-item>
					<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
				</form-item>
				<form-item>
					<el-button type="primary" size="mini" @click="form.website=q.website;visible.add=true">添加配置</el-button>
				</form-item>
			</template>
			<template #advanced>
				<form-item-countries v-model="queryForm.countryCode" :site="queryForm.website"></form-item-countries>
				<form-item label="品类:" style="width:200px;">
					<el-input v-model.trim="queryForm.categoryCode" @input="queryForm.categoryCode=queryForm.categoryCode.toUpperCase()" size="mini" placeholder="请输入"></el-input>
				</form-item>
				<form-item-publishtypes v-model="queryForm.publishType" clearable></form-item-publishtypes>
				<form-item label="发布状态:" style="width:200px;">
					<el-select v-model="queryForm.active" size="mini" :clearable="true">
						<el-option label="开启" :value="true"></el-option>
						<el-option label="暂停" :value="false"></el-option>
					</el-select>
				</form-item>
			</template>
		</advanced-search-bar>
		<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
		<alert v-if="multipleSelection.length > 0">
			已选择 <span class="text-primary">{{multipleSelection.length}}</span> 个。
			<el-dropdown @command="handleCommand">
			      <span class="text-primary">
			        批量操作
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="active">发布</el-dropdown-item>
			        <el-dropdown-item command="deactive">取消发布</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
		</alert>
		<audience-table class="mt-3 w-100" :tableData="tableData" @handleSelectionChange="handleSelectionChange" @changePublish="changeActive" @editRow="editRow"></audience-table>
		<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
			:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
		</el-pagination>
		<el-dialog :visible.sync="visible.add" :before-close="handleClose" width="1000px">
			<el-form ref="form" :model="form" :rules="rules" :hide-required-asterisk="true" label-width="80px" size="mini">
				<el-row class="border-bottom">
					<el-col :span="8">
						<el-form-item-sites :abbr="true" v-model="form.website" label-width="80px" prop="website" @update="formWebsiteChanged" size="mini"></el-form-item-sites>
					</el-col>
					<el-col :span="8">
						<el-form-item-countries v-model="form.countryCode" label-width="80px" :site="form.website" size="mini" :clearable="false"></el-form-item-countries>
					</el-col>
					<el-col :span="8">
						<el-form-item label="品类:" prop="category">
							<el-input v-model.trim="form.category" @input="form.category=form.category.toUpperCase()" placeholder="请输入"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="发布类型:" prop="publishType" label-width="80px">
							<form-item-publishtypes :with-layout="false" v-model="form.publishType" addClass="w-100"></form-item-publishtypes>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="性别:">
							<el-select v-model="form.gender" class="w-100">
								<el-option v-for="(item, index) in genders" :label="item.label" :value="item.key" :key="index"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="年龄:">
							<div class="d-flex flex-row align-items-center">
								<el-input-number :min="13" :max="65" :step="1" v-model="form.ageMin" style="flex-basis:40%;" :controls="false"></el-input-number>
								<div style="flex-basis:20%;text-align:center;">-</div>
								<el-input-number :min="13" :max="65" :step="1" v-model="form.ageMax" style="flex-basis:40%;" :controls="false"></el-input-number>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12"></el-col>
				</el-row>
				<el-row class="mt-3">
					<el-col :span="12">
						<form-custom-audiences ref="form-custom-audiences" :audiences="form.audiences" :website="form.website" @reloadData="$refs['form-custom-audiences'].__initCustomAudiences()"></form-custom-audiences>
					</el-col>
					<el-col :span="12">
						<form-interests :interests="form.interests"></form-interests>
					</el-col>
				</el-row>
				
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="doCreateOrUpdate" size="mini">确 定</el-button>
			</div>
		</el-dialog>
	</el-card>
</template>

<script>
	import {
		mapActions,
		mapGetters
	} from 'vuex'
	import AudienceTable from '@/components/contents/audience-table.vue'
	import config from './minixs/index.js'
	import FormCustomAudiences from '@/components/contents/form-custom-audiences.vue'
	import FormInterests from '@/components/contents/form-interests.vue'
	import {fetchJSONByApis} from '@/apis/index.js'
	import Lodash from 'lodash'
	const InitialForm = {
		website: '',
		countryCode: '',
		publishType: 'Normal',
		gender: 2,
		category: '',
		ageMin: 25,
		ageMax: 64,
		interests: [{
			text: ''
		}],
		audiences: [{
			includes: [],
			excludes: []
		}]
	}
	export default {
		mixins:[config],
		components: {
			AudienceTable,
			FormCustomAudiences,
			FormInterests
		},
		data() {
			return {
				queryForm: {
					countryCode: '',
					active: null,
					website: '',
					categoryCode: '',
					publishType: ''
				},
				tableData: [],
				q: {},
				visible: {
					add: false
				},
				form: {
					...InitialForm
				},
				rules: {
					website: [{
						required: true,
						message: '站点不能为空'
					}],
					countryCode: [{
						required: true,
						message: '国家不能为空'
					}],
					category: [{
						required: true,
						message: '品类不能为空'
					}],
					publishType: [{
						required: true,
						message: '发布类型不能为空'
					}]
				},
				namespace: 'fb-contents-audience'
			}
		},
		computed: {
			...mapGetters({
				genders: 'fb-contents-audience/genders'
			}),
			ageRange() {
				return `${this.form.age[0]}~${this.form.age[1]}`
			}
		},
		methods: {
			handleList(content) {
				this.tableData = content.map(v => {
					const interests = v.interests.map(k => {
						var text = ''
						try {text = JSON.parse(k.title).interests.map(l => l.name).join(',')} catch (e) {}
						return { text }
					})
					return {
						...v,
						interests
					}
				})
			},
			fillForm(data) {
				const { interests, audiences } = data
				var _interests = [{text: ''}]
				try {
					_interests = interests.map(k => {
						var text = ''
						try {text = JSON.parse(k.title).interests.map(l => l.name).join(',')} catch (e) {}
						return { text }
					})
				} catch (_) {}
				if (_interests.length === 0) {
					_interests = [{text: ''}]
				}
				var _audiences = [{includes: [], excludes: []}]
				try {
					_audiences = audiences.map(({exclude, include}) => {
						const data = {excludes: [], includes: []}
						try {
							const excludeIds = JSON.parse(exclude).map(v => v.id)
							data['excludes'] = excludeIds
						} catch (_) {}
						try {
							const includeIds = JSON.parse(include).map(v => v.id)
							data['includes'] = includeIds
						} catch (_) {}
						return data
					})
				} catch (_) {}
				if (_audiences.length === 0) {
					_audiences = [{includes: [], excludes: []}]
				}
				this.form = {
					...InitialForm,
					...data, 
					interests: _interests,
					audiences: _audiences
				}
			},
			labelIndex(index) {
				if (index < 9) {
					return `0${index+1}`
				}
				return `${index+1}`
			},
			async asyncUpdateNestedForm(data) {
				return new Promise((resolve, reject) => {
					const apis = data.interests.map((item) => {
						return this.$store.dispatch(`${this.namespace}/fetchInterests`, {website: data.website, keyword: item.text})
					})
					fetchJSONByApis(...apis)
					.then(result => {
						const interests = result.map(v => {
							return {
								interests: v.filter(k => k.valid) 
							}
						}).filter(v => v.interests.length > 0)
						const audiences = this.$refs['form-custom-audiences'].buildFormAudiences()
						resolve({
							...data,
							interests,
							audiences
						})
					})
					.catch(err => {
						reject(err)
					})
				})
			},
			formWebsiteChanged() {
				this.$refs['form-custom-audiences'].watchWebsiteChanged()
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					console.log(this.form)
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
						this.$refs['form-custom-audiences'].__initCustomAudiences()
					})
				} else {
					this.form = Lodash.cloneDeep(InitialForm)
				}
			}
		}
	}
</script>

<style>
</style>
