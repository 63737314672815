<template>
	<el-dialog :visible.sync="visible" :before-close="handleClose" width="600px" :append-to-body="true" :show-close="false" :destroy-on-close="true">
		<div slot="title" class="d-flex flex-row align-items-center justify-content-between">
			<div class="mr-2">Lookalike Audience source</div>
			<el-select v-model="form.sourceId" size="mini" @change="sourceChanged" class="mr-3">
				<el-option v-for="item in sources" :label="item.sourceName" :value="item.sourceId">
					<span style="float:left;font-size:12px">{{ item.sourceName }}</span>
					<span style="float:right;color:#8492a6; font-size:10px;margin-left:20px;">{{ item.sourceType }}</span>
				</el-option>
			</el-select>
		</div>
		<div id="event-condition" style="height:60px;" class="collapse">
			<div class="border p-2 mb-2">
				<form-item label="事件" style="margin:0 !important;">
					<el-select v-model="form.event" size="mini">
						<el-option v-for="event in events" :label="event.label" :value="event.key"></el-option>
					</el-select>
				</form-item>
			</div>
		</div>
		<form-item label="国家:">
			<el-select v-model="form.countryCodes" size="mini" clearable class="w-100" filterable @change="calcEstimatedAudiences" multiple>
				<el-option v-for="country in countries" :label="country.name" :value="country.code" :class="{'d-none': country.code === 'ALL'}">
					<div class="d-flex flex-row align-items-center justify-content-between">
						<div>{{country.name}}</div>
						<div>{{country.code}}</div>
					</div>
				</el-option>
			</el-select>
		</form-item>
		<div style="height:300px;">
			<div>Looklike 范围</div>
			<div class="range-bar-container">
				<div class="range-bar">
				</div>
				<div v-if="estimatedAudiences.length>0" v-for="(item, index) in estimatedAudiences" class="range-bar-cover" :style="computedCoverStyle(item, index)" :key="index">
				</div>
				<div v-for="num in serialNumber" class="range-dot-container" :style="computedLeft(num)" @click="selectIndex(num)" :class="isSelected(num)">
					<div class="range-dot-text-container">
						{{num}}%
					</div>
				</div>
			</div>
			<div v-if="form.ratioRange.length > 1">
				<div>预计新建{{form.ratioRange.length - 1}}个 Looklike audience:</div>
				<div v-for="item in estimatedAudiences">
					{{item.text}}
				</div>
			</div>
		</div>
		<div slot="footer" class="dialog-footer border-top pt-3">
			<el-button @click="$emit('update:visible', false)" size="mini">取 消</el-button>
			<el-button type="primary" size="mini" @click="saveLookAlikeAudience">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import {mapGetters, mapState} from 'vuex'
	import Lodash from 'lodash'
	
	const DEFAULT_FORM = {
		  countryCodes: [],
		  event: '',
		  ratioRange: [],
		  sourceId: '',
		  sourceName: '',
		  sourceType: '', //PAGE, AUDIENCE
		  website: ''
	}
	export default {
		mixins: [Common],
		inject: ['adminLayout'],
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			q: {
				type: Object,
				default: (() => {})
			},
			website: {
				type: String,
				default: ''
			}
		},
		watch: {
			visible(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.form = {...Lodash.cloneDeep(DEFAULT_FORM), website: this.website}
						this.calcEstimatedAudiences()
						this.__init()
					})
				} else {
					this.$nextTick(() => {
						this.form = Lodash.cloneDeep(DEFAULT_FORM)
					})
				}
			}
		},
		created() {
			this.$store.dispatch('getCountries')
		},
		computed: {
			...mapGetters({
				events: 'fb-contents-audience/events'
			}),
			...mapState({
				countries: state => state.content.countries,
			})
		},
		data() {
			return {
				serialNumber: new Array(11).fill(0).map((item, index) => index),
				sources: [],
				form: Lodash.cloneDeep(DEFAULT_FORM),
				estimatedAudiences: []
			}
		},
		methods: {
			__init() {
				this.adminLayout.showLoading(true)
				this.$store.dispatch('fb-contents-audience/getAllSources', this.website)
				.then(sources => {
					this.sources = sources
					this.adminLayout.hideLoading()
				})
				.catch(err => {
					this.$showErrMsg(err)
					this.adminLayout.hideLoading()
				})
			},
			sourceChanged() {
				const source = this.sources.find(v => v.sourceId === this.form.sourceId)
				this.calcEstimatedAudiences()
				if (source.sourceType === 'PIXEL') {
					$('#event-condition').collapse('show')
				} else {
					$('#event-condition').collapse('hide')
				}
			},
			computedLeft(num) {
				return 'left:' + num * 9.45 + '%'
			},
			selectIndex(num) {
				const index = this.form.ratioRange.findIndex(v => v === num)
				if (index === -1) {
					this.form.ratioRange.push(num)
				} else {
					this.form.ratioRange.splice(index, 1)
				}
				this.calcEstimatedAudiences()
			},
			isSelected(num) {
				const val = this.form.ratioRange.find(v => v === num)
				return val === undefined ? '' : 'range-is-selected'
			},
			calcEstimatedAudiences() {
				const estimatedAudiences = []
				var lastNum = null
				const source = this.sources.find(v => v.id === this.form.sourceId)
				const suffix = source && ` - ${source.sourceName}` || ''
				const forkList = this.form.ratioRange.slice()
				forkList.sort((a, b) => a-b)
				forkList.forEach((num) => {
					if (typeof(lastNum) === 'number') {
						const prefix = [this.form.countryCodes.join(','), `${lastNum}%-${num}%`].filter(v => !!v).join(',')
						estimatedAudiences.push({
							min: lastNum,
							max: num,
							text: `Lookalike(${prefix})` + suffix
						})
					}
					lastNum = num
				}, [])
				this.estimatedAudiences = estimatedAudiences
			},
			computedCoverStyle(item, index) {
				const {min, max} = item
				return 'left:' + min * 9.45 + '%;width:' + (max-min) * 9.45 + '%;background:#409EFF;transform:translateX(18px);opacity:' + (index + 1) * 0.1 + ';' 
			},
			async validateForm() {
				return new Promise((resolve, _) => {
					if (this.form.sourceId === '') {
						resolve(false)
						return
					}
					if (this.form.countryCodes.length === 0) {
						resolve(false)
						return
					}
					if (this.form.ratioRange.length === 0) {
						resolve(false)
						return
					}
					resolve(true)
				})
			},
			async convertFormForSubmit() {
				return new Promise((resolve, _) => {
					const source = this.sources.find(v => v.sourceId === this.form.sourceId)
					resolve({...this.form, ...source})
				})
			},
			saveLookAlikeAudience() {
				this.validateForm()
				.then(flag => {
					if (flag) {
						this.adminLayout.showLoading(true)
						this.convertFormForSubmit()
						.then(form => {
							this.$store.dispatch('fb-contents-audience/newItemOfLookalikeAudience', form)
							.then(res => {
								this.adminLayout.hideLoading()
								this.$showSuccess('创建成功')
								this.$emit('update:visible', false)
								this.$emit('reloadData')
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						})
					} else {
						this.$showErrMsg('请完整填写配置后再保存')
					}
				})
			}
		}
	}
</script>

<style lang="scss">
	.range-bar-container {
		margin-top: 40px;
		position: relative;
		padding: 0 20px;
		height: 40px;
		.range-bar {
			background-color:#f7f7f7;
			height:5px;
		}
		.range-bar-cover {
			height: 5px;
			position: absolute;
			top: 0;
		}
		.range-dot-container {
			color: #ccc;
			position: absolute;
			font-size: 10px;
			white-space: nowrap;
			width:40px;
			height:40px;
			text-align: center;
			line-height: 40px;
			top:-2px;
			cursor: pointer;
		}
		.range-dot-container:hover, .range-dot-container.range-is-selected {
			.range-dot-text-container {
				color: black;
			}
			.range-dot-text-container:before {
				border: 2px solid #409EFF;
			}
		}
		.range-dot-text-container {
			position: relative;
		}
		.range-dot-text-container:before {
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 50%;
			border: 2px solid #ccc;
			position: absolute;
			background-color: white;
			top:0;
		}
	}
	
</style>