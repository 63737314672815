<template>
	<div class="form-custom-audiences-container">
		<div v-for="(item, index) in audiences" class="px-3" :key="index">
			<div class="w-100 d-flex flex-row align-items-center justify-content-between mb-2">
				<div>Custom audience {{index|labelIndex}}:</div>
			</div>
			<div >
				<table class="form-custom-audiences-table">
					<tbody>
						<tr>
							<td>include</td>
							<td>
								<el-select v-model="item.includes" size="mini" class="w-100" multiple clearable filterable>
									<el-option v-for="(audience, index1) in allCustomAudiences" :label="audience.name" :value="audience.id" :key="`${index}-${index1}-0`">
										<span style="float:left;font-size:12px">{{ audience.name }}</span>
										<span style="float:right;color:#8492a6; font-size:10px;margin-left:20px;">{{ audience.id }}</span>
									</el-option>
								</el-select>
							</td>
							<td colspan="2">
								<el-button v-if="index===0" class="ml-2" style="border:1px dashed;width:60px;" size="mini" @click="doAction('add')">+添加</el-button>
								<el-button v-else type="text" class="text-danger ml-2" @click="doAction('remove', index)" style="width:60px;" size="mini">删除</el-button>
							</td>
						</tr>
						<tr>
							<td>exclude</td>
							<td>
								<el-select v-model="item.excludes" size="mini" class="w-100" multiple clearable filterable>
									<el-option v-for="(audience, index1) in allCustomAudiences" :label="audience.name" :value="audience.id" :key="`${index}-${index1}-1`">
										<span style="float:left;font-size:12px">{{ audience.name }}</span>
										<span style="float:right;color:#8492a6; font-size:10px;margin-left:20px;">{{ audience.id }}</span>
									</el-option>
								</el-select>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<el-dropdown size="mini" @command="handleCommand" class="create-audience-dropdown">
		  <small class="p-2 border rounded">
		    创建新audience
		  </small>
		  <el-dropdown-menu slot="dropdown">
		    <el-dropdown-item command="CustomAudience">Custome audience</el-dropdown-item>
		    <el-dropdown-item command="LookalikeAudience">Lookalike audience</el-dropdown-item>
		  </el-dropdown-menu>
		</el-dropdown>
		<el-drawer
		  size="600px"
		  :visible.sync="drawer"
		  direction="rtl"
		  :show-close="false"
		  :append-to-body="true"
		  :withHeader="false"
		  :before-close="handleClose">
		  <custom-audiences-list v-if="componentKey" :namespace="selNamespace" :key="componentKey" @closeDraw="drawer=false" :website="website"></custom-audiences-list>
		</el-drawer>
	</div>
</template>

<script>
	import Common from '@/common/mixins/common.js'
	import CustomAudiencesList from './custom-audiences-list.vue'
	import {uuid} from 'vue-uuid'
	export default {
		mixins: [Common],
		inject: ['adminLayout'],
		components: {
			CustomAudiencesList
		},
		provide() {
			return {
				formCustomAudiences: this
			}
		},
		props: {
			audiences: {
				type: Array,
				default: (() => {
					return [{includes: [], excludes: []}]
				})
			},
			website: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				drawer: false,
				selNamespace: '',
				componentKey: null,
				allCustomAudiences: []
			}
		},
		filters: {
			labelIndex(index) {
				const v = index + 1
				return v < 10 ? `0` + v : v
			}
		},
		methods: {
			doAction(action, payload) {
				switch (action) {
					case 'remove':
						this.$confirm(`此操作将永久删除该配置, 是否继续?`, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.audiences.splice(payload, 1)
						})
						break
					case 'add':
						this.audiences.push({includes:[],excludes:[]})
						break
					default:
						break
				}
			},
			handleCommand(command) {
				this.componentKey = uuid.v4()
				this.selNamespace = command
				this.drawer = true
			},
			watchWebsiteChanged() {
				this.audiences.forEach((v) => {
					v.includes = []
					v.excludes = []
				})
				this.__initCustomAudiences()
			},
			__initCustomAudiences(implicit=false) {
				if (!implicit) {
					this.allCustomAudiences = []
					// 获取该站点所有配置的自定义受众
					this.adminLayout.showLoading(true)
				}
				this.$store.dispatch('fb-contents-audience/fetchAllAudiences', this.website)
				.then(result => {
					this.allCustomAudiences = Object.keys(result).map(key => {
						return {
							id: key + '',
							name: result[key]
						}
					})
					if (!implicit) {
						this.adminLayout.hideLoading()
					}
				})
				.catch(err => {
					this.$showErrMsg(err)
					if (!implicit) {
						this.adminLayout.hideLoading()
					}
				})
			},
			buildFormAudiences() {
				return this.audiences.map(({excludes, includes}) => {
					return {
						excludes: excludes.map(id => this.allCustomAudiences.find(i => i.id === id)).filter(v => v !== undefined),
						includes: includes.map(id => this.allCustomAudiences.find(i => i.id === id)).filter(v => v !== undefined)
					}
				}).filter(({excludes, includes}) => includes.length > 0 || excludes.length > 0)
			}
		}
	}
</script>

<style lang="scss" scoped>
	:deep(table.form-custom-audiences-table) {
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 5px;
		td:nth-child(n+1) {
			width: 15%;
		}
		td:nth-child(n+2) {
			width: 65%;
		}
		td:nth-child(n+3) {
			width: 20%;
		}
	}
	.form-custom-audiences-container {
		position: relative;
		.create-audience-dropdown {
			position: absolute !important;
			top: 0;
			right: 35px;
		}
	}
</style>