<template>
	<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange" @select="pinSelect">
		<el-table-column type="selection" width="55">
		</el-table-column>
		<el-table-column label="站点" prop="website" min-width="120">
		</el-table-column>
		<el-table-column label="国家" prop="countryCode" min-width="120">
		</el-table-column>
		<el-table-column label="品类" prop="category" min-width="120">
		</el-table-column>
		<el-table-column label="性别" prop="gender" min-width="120">
			<template slot-scope="scope">
				{{scope.row|gender(genders)}}
			</template>
		</el-table-column>
		<el-table-column label="年龄" min-width="120">
			<template slot-scope="scope">
				{{scope.row|age}}
			</template>
		</el-table-column>
		<el-table-column label="Interest" min-width="150">
			<template slot-scope="scope">
				<el-popover
				    width="400"
				    trigger="hover">
					<ul>
						<li v-for="(item,index) in scope.row.interests" :key="index">
							{{item.text}}
						</li>
					</ul>
					<div slot="reference" style="white-space:nowrap;text-overflow:ellipsis;overflow:hidden;">{{scope.row|interests}}</div>
				  </el-popover>
			</template>
		</el-table-column>
		<el-table-column label="发布类型" prop="publishType" min-width="100">
		</el-table-column>
		<el-table-column label="发布状态" min-width="100">
			<template slot-scope="scope">
				<el-switch v-model="scope.row.active" @change="$emit('changePublish', scope.row)"></el-switch>
			</template>
		</el-table-column>
		<el-table-column label="操作" min-width="100">
			<template slot-scope="scope">
				<el-button type="text" @click="$emit('editRow', scope.row)">编辑</el-button>
			</template>
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import {mapGetters} from 'vuex'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: []
			}
		},
		computed: {
			...mapGetters({
				genders: 'fb-contents-audience/genders'
			})
		},
		filters: {
			gender(row, genders) {
				const res = genders.find(v => v.key === row.gender)
				return res && res.label
			},
			age(row) {
				const {ageMin, ageMax} = row
				try {
					if (ageMin === ageMax) {
						return maxAge
					}
					return `${ageMin}-${ageMax}`
				} catch (e) {
					console.error(e)
					return ''
				}
			},
			interests(row) {
				if (Array.isArray(row.interests)) {
					return row.interests.map(v=> v.text).join('\n')
				}
				return ''
			}
		}
	}
</script>

<style>
</style>
